<template>
  <div class="main-wrapper" data-app>
    <div class="content">
      <div class="container p-0 p-sm-auto">
        <div v-if="loading">
          <v-row>
            <v-skeleton-loader
              class="mx-auto my-5 col-12 col-md-4"
              max-width="300"
              type="article"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto my-2 col-12 col-md-4"
              max-width="300"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto col-12 col-md-4"
              max-width="300"
              type="article"
            ></v-skeleton-loader>
          </v-row>
        </div>

        <div class="" v-if="company.data">
          <!-- HEAD CONTENT -->

          <!-- <v-row no-gutters class="justify-content-center align-items-center p-4">
            <h4 class="mb-3 text-center w-100">{{ company.name }}</h4>
            <div class="col-6 col-lg-3 order-lg-2 p-0 p-sm-2 p-lg-0 d-flex">
              <a href="#">
                <img
                  v-if="company.logo && company.logo.route"
                  :src="company.logo.route"
                  class="w-100 asociation-logo"
                  alt=""
                />
              </a>
            </div>

            <div class="col-lg-4 order-lg-3 text-lg-right pl-lg-4 pb-2 pb-lg-0">
              <img
                class="country__flag position-relative top-0 right-0"
                :src="company.countries[0].flag"
                alt="Country to wich the franchising association belongs"
              />
              <p
                v-if="
                  company.data.find((item) => item.id === 8) &&
                  company.data.find((item) => item.id === 8).company_data
                "
                class="description-text"
                v-html="company.data.find((item) => item.id === 8).company_data.value"
              ></p>
           
            </div>
            <div
              class="col-lg-4 order-lg-1 text-lg-left align-self-lg-center pr-lg-2"
              v-if="company.data"
            >
              <v-row
                no-gutters
                class="mb-2"
                v-if="
                  company.data.find((item) => item.id === 6) &&
                  company.data.find((item) => item.id === 6).company_data
                "
              >
                <v-icon class="col-1" color="#172b4d">mdi-cellphone</v-icon>
                <a
                  class="text-link pl-2 col"
                  :href="`tel:${extractContent(
                    company.data.find((item) => item.id === 6).company_data.value
                  )}`"
                  v-html="
                    `${extractContent(
                      company.data.find((item) => item.id === 6).company_data.value
                    )}`
                  "
                >
                </a>
              </v-row>

              <v-row
                no-gutters
                class="mb-2"
                v-if="
                  company.data.find((item) => item.id === 5) &&
                  company.data.find((item) => item.id === 5).company_data.value
                "
              >
                <v-icon class="col-1" color="#172b4d">mdi-email</v-icon>
                <a
                  class="text-link pl-2 col"
                  :href="`mailto:${extractContent(
                    company.data.find((item) => item.id === 5).company_data.value
                  )} `"
                  v-html="
                    `${extractContent(
                      company.data.find((item) => item.id === 5).company_data.value
                    )}`
                  "
                >
                </a>
              </v-row>
              <v-row
                no-gutters
                class="mb-2"
                v-if="
                  company.data.find((item) => item.id === 4) &&
                  company.data.find((item) => item.id === 4).company_data.value
                "
              >
                <v-icon class="col-1" color="#172b4d">mdi-map-marker</v-icon>
                <p
                  class="m-0 pl-2 col"
                  v-html="company.data.find((item) => item.id === 4).company_data.value"
                ></p>
              </v-row>
              <v-row
                no-gutters
                class="mb-2"
                v-if="company.website && company.website != ''"
              >
                <v-btn
                  small
                  class="text-link"
                  :href="company.website"
                  :title="company.website"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon>mdi-web</v-icon>
                  Sitio Web
                </v-btn>
              </v-row>
            </div>
          </v-row> -->

          <v-row class="justify-content-center justify-content-sm-start mx-0 mb-4">
            <div
              class="col-6 col-sm-4 col-lg-3 order-2 order-sm-1 p-0 p-sm-2 p-lg-0 d-flex justify-content-center"
            >
              <img
                v-if="company.logo && company.logo.route"
                :src="company.logo.route"
                class="w-100 asociation-logo"
              />
            </div>
            <div class="mb-3 order-1 order-sm-2 col-12 col-sm">
              <h5 class="">
                {{ company.name }} <br />
                <!-- </v-row> -->
              </h5>
              <p class="mb-0 description-text">{{ company.description }}</p>
              <img
                class="country__flag position-relative top-0 right-0 justify-self-start"
                :src="company.countries[0].flag"
                alt="Country to wich the franchising association belongs"
              />
              <v-btn
                small
                absolute
                bottom
                right
                class="text-link ml-auto"
                :href="company.website"
                :title="company.website"
                target="_blank"
                rel="noopener"
              >
                <v-icon>mdi-web</v-icon>
                Sitio Web
              </v-btn>
            </div>
          </v-row>

          <!-- COMPANY DATA -->

          <v-row no-gutters class="" v-for="data in arrayOfData" :key="data.id">
            <v-toolbar dense height="40" flat color="#0c2cb0d1" width="100%" class="">
              <v-toolbar-title class="text-white">{{ data.title }}</v-toolbar-title>
            </v-toolbar>
            <v-row class="m-0">
              <div class="col-12 col-sm-6" v-for="field in data.data" :key="field.id">
                <h6>{{ field.description }}</h6>
                <v-divider class="my-1"></v-divider>
                <p class="m-0" v-html="field.company_data.value"></p>
              </div>
            </v-row>
          </v-row>

          <!-- DIRECTIVE  -->
          <directors
            class="my-4"
            :items="company.directors"
            :title="'Junta Directiva'"
            v-if="company.directors.length > 0"
          ></directors>

          <!-- AFFILIATES -->
          <v-row no-gutters class="flex-column my-5" v-if="companies.length > 0">
            <h4 class="px-4">Afiliados</h4>
            <v-card
              elevation="4"
              class="row p-2 my-2 m-1 m-lg-2 list-item"
              v-for="(item, id) in companies"
              :key="id"
            >
              <picture
                class="col-4 p-2 p-sm-0 d-flex align-items-center justify-content-center mx-auto list-item__img-container"
              >
                <img
                  v-if="item.logo && item.logo.route"
                  :src="item.logo.route"
                  class="img-fluid"
                />
              </picture>
              <div class="col-8">
                <h6 class="text-primary">{{ item.name }}</h6>
                <v-row no-gutters class="mb-2" v-if="item.website && item.website != ''">
                  <v-chip
                    class="p-2"
                    small
                    outlined
                    color="#0009"
                    v-for="(category, index) in item.categories"
                    :key="index"
                  >
                    {{ category.name }}
                  </v-chip>
                </v-row>
                <p :class="{ 'list-item__description': $vuetify.breakpoint.xs }">
                  {{ item.description }}
                </p>
                <v-btn
                  x-small
                  outlined
                  class="text-link"
                  :href="company.website"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon x-small>mdi-web</v-icon>
                  Sitio Web
                </v-btn>
              </div>
            </v-card>
            <v-pagination
              class="my-4"
              circle
              color="#0c2c7c"
              v-model="page"
              :length="pages"
              @input="pagination($event)"
            ></v-pagination>
          </v-row>

          <!-- GALERY -->
          <v-row no-gutters class="" v-if="galeryImages.length > 0">
            <swiper class="p-0 pb-5" :options="swiperOption">
              <swiper-slide
                class="card shadow shadow-lg--hover pointer galery-slide"
                v-for="(item, id) in galeryImages"
                :key="id"
              >
                <v-img
                  :src="item.route"
                  max-width="100%"
                  min-width="200"
                  contain
                  class="mx-auto"
                >
                </v-img>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Directors from 'Components/FrontendComponents/Directors.vue'
  export default {
    name: 'Asociations',

    components: { Directors },
    created() {
      this.getCompany()
      // this.getCompanies()
    },
    data() {
      return {
        company: {},
        companies: [],
        galeryImages: [],
        page: 1,
        pages: null,
        totalItems: null,
        itemsPerPage: 5,
        loadingCompanies: false,
        loadingCompaniesText: '',
        arrayOfData: [],
        typeDatas: [
          { code: 13, name: 'INFORMACION GENERAL' },
          { code: 1, name: 'OBJETIVO' },
          { code: 10, name: 'ACTIVIDADES' },
          { code: 6, name: 'RESEÑA' },
          { code: 7, name: 'UBICACIÓN Y DATOS DE CONTACTO' },
          // { code: 14, name: "CONTRATACIONES" },
          // { code: 2, name: "DATOS DE LA EMPRESA FRANQUICIANTE" },
          // { code: 3, name: "DATOS OPERATIVOS" },
          // { code: 11, name: "EXPERIENCIA" },
          // { code: 4, name: "INFORMACIÓN ECONÓMICA" },
          // { code: 9, name: "INFORMACIÓN SOBRE UBICACIÓN Y LOCAL REQUERIDO" },
          // { code: 5, name: "OTROS DATOS IMPORTANTES" },
          // { code: 15, name: "PRODUCTOS O SERVICIOS QUE OFRECEN" },
          // { code: 12, name: "SERVICIOS ESPECIALIZADOS" },
          // { code: 8, name: "TIPO DE FRANQUICIAS QUE OFRECEN" },
        ],
        swiperOption: {
          centerInsufficientSlides: true,
          speed: 300,
          autoplay: {
            delay: 300000,
          },

          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            type: 'bullets',
          },

          breakpoints: {
            1440: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
            1025: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 50,
            },

            320: {
              slidesPerView: 1,
              spaceBetween: 50,
            },
          },
        },
      }
    },
    methods: {
      async getCompany() {
        this.loading = true
        axios.get('companies-by-slug/' + this.$route.params.slug).then((response) => {
          this.company = response.data
          this.loading = false
          if (this.company) {
            this.message = 'Esta información no está disponible actualmente'
          }
          this.galeryImages = this.company.uploads.filter((file) => file.type === 'image')
          this.typeDatas.forEach((typeData) => {
            if (
              this.company.data.filter((backData) => backData.type === typeData.code)
                .length > 0
            ) {
              this.arrayOfData.push({
                title: typeData.name,
                data: this.company.data.filter(
                  (backData) => backData.type == typeData.code
                ),
              })
            }
          })
          this.getCompanies()
        })
      },
      async getCompanies() {
        this.loadingCompanies = true
        let searchString = ''
        if (this.filterActivated) {
          if (this.filters.search != '') {
            searchString = '&search=' + this.filters.search
          }
        }
        axios
          .get(
            'companies-list?active=1&type=0&page=' +
              this.page +
              '&company_id=' +
              this.company.id +
              searchString
          )
          .then((response) => {
            this.companies = response.data.data
            if (this.companies.length < 1) {
              this.loadingCompaniesText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.loadingCompanies = false
          })
      },
      extractContent(html) {
        return new DOMParser().parseFromString(html, 'text/html').documentElement
          .textContent
      },
      async pagination(page) {
        this.page = page
        await this.getCompanies()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .listing-item-container.list-layout .listing-item {
    width: 100%;
    height: 200px;
  }
  .country__flag {
    margin: 5px 0;
    width: 35px;
    height: 25px;
  }
  .description-text {
    > p {
      line-height: 1.5;
      font-size: 0.9rem;
    }
  }
  .list-item__description {
    font-size: 0.8rem;
    line-height: 1.5;
  }
  p > p,
  a > p {
    margin: 0;
  }
  .list-item__img-container {
    height: 180px !important;
    display: flex;
    justify-content: center;
  }
  .img-fluid {
    object-fit: contain !important;
    width: 100%;
    height: 100%;
    min-height: 100px !important;
    align-self: center;
  }
  .v-btn__content {
    text-transform: initial;
  }
  .asociation-logo {
    max-width: 200px;
  }
  .v-toolbar__title {
    font-size: 1rem;
  }
</style>
